<template>
  <CCard>
    <CCardHeader><h4 class="subhead-modal">Idea Tank</h4></CCardHeader>
    <CCardBody>
      <CRow class="mb-2">
        <CCol sm="10">
          <CLink
            class="card-header-action btn-minimize"
            @click="formCollapsed = !formCollapsed"
          >
            <CIcon
              size="lg"
              name="cil-chevron-double-down"
              v-if="formCollapsed"
            />
            <CIcon size="lg" name="cil-chevron-double-up" v-else />
          </CLink>
          <label><b>Search box</b></label>
        </CCol>
      </CRow>

      <CCollapse :show="formCollapsed">
        <CRow>
          <CCol md="3">
            <CCard class="card border-secondary">
              <CCardHeader>
                <label>Creator Details</label>
              </CCardHeader>
              <CCardBody>
                <CInput label="Creator" v-model="searchObj.Creator" />
                <CSelect
                  label="Business Unit"
                  :options="buList"
                  placeholder=""
                  v-model="searchObj.BU"
                  :value.sync="searchObj.BU"
                />
              </CCardBody>
            </CCard>
          </CCol>
          <CCol md="3">
            <CCard class="card border-secondary event-date">
              <CCardHeader>
                <label>Ideation event detail</label>
              </CCardHeader>
              <CCardBody>
                <CSelect
                  label="Industry"
                  :options="IndustryList"
                  placeholder=""
                  v-model="searchObj.Industry"
                  :value.sync="searchObj.Industry"
                />
                <label class="">Event date</label>
                <DatePicker
                  class="custom-input-date"
                  :first-day-of-week="2"
                  :masks="{ input: ['YYYY/MM/DD'], data: ['YYYY/MM/DD'] }"                  
                  :value.sync="searchObj.EventDate"
                  v-model="searchObj.EventDate"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      class="bg-white border px-2 py-1 rounded"
                      :value="inputValue"
                      v-on="inputEvents"
                      placeholder="YYYY/MM/DD"                      
                      readonly
                    />
                  </template>
                </DatePicker>
              </CCardBody>
            </CCard>
          </CCol>
          <CCol md="3">
            <CCard class="card border-secondary">
              <CCardHeader>
                <label>Market/ Customer</label>
              </CCardHeader>
              <CCardBody>
                <!--<CInput label="Insight No." v-model="searchObj.InsightNo" />
                <CInput
                  label="Insight detail"
                  v-model="searchObj.InsightDetail"
                />-->
                <CSelect
                  label="Industry"
                  :options="IndustryList"
                  placeholder=""
                  v-model="searchObj.Industry"
                  :value.sync="searchObj.Industry"
                />
                <CSelect
                  label="Segment"
                  :options="IndustryList"
                  placeholder=""
                  v-model="searchObj.Industry"
                  :value.sync="searchObj.Industry"
                />
                <CSelect
                  label="Sub Segment"
                  :options="IndustryList"
                  placeholder=""
                  v-model="searchObj.Industry"
                  :value.sync="searchObj.Industry"
                />
              </CCardBody>
            </CCard>
          </CCol>
          <CCol md="3">
            <CCard class="card border-secondary">
              <CCardHeader><label>Idea detail</label></CCardHeader>
              <CCardBody>
                <CInput label="Idea No." v-model="searchObj.IdeaNo" />
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CCollapse>
      <CRow>
        <CCol></CCol>
        <CCol class="text-center">
          <CInput label="Keyword" v-model="searchObj.IdeaDetail" horizontal>
            <template #prepend-content
              ><CIcon name="cil-magnifying-glass"
            /></template>
          </CInput>
        </CCol>
        <CCol></CCol>
      </CRow>
      <CRow>
        <CCol class="text-center">
          <CButton color="info-custom" class="mr-1" @click="OnExport()">Export</CButton>
          <CButton color="dark" class="mr-1 text-white" @click="ClearValue()">Clear</CButton>
          <CButton color="primary" class="text-white" @click="SearchIdea()"
            >Search</CButton
          >
        </CCol>
      </CRow>
      <CRow>
        <CCol md="12">
          <CDataTable
            :items="IdeaTankData"
            :fields="fields"
            items-per-page-select
            :items-per-page="10"
            :loading="IsLoading"
            hover
            sorter
            pagination
          >
            <template #action="item">
              <td>
                <!-- <CButton v-if="item.item.ILike" size="sm" color="success" variant="ghost" class="mr-1 active" @click="onClickLike(item.item)">
                  <CIcon name="cil-thumb-up"></CIcon>
                </CButton>
                <CButton v-else size="sm" color="success" variant="ghost" class="mr-1" @click="onClickLike(item.item)">
                  <CIcon name="cil-thumb-up"></CIcon>
                </CButton> -->

                <CButton size="sm" color="info" v-on:click="onClickAddConcept(item.item)">
                  Add Concept
                </CButton>
              </td>
            </template>

            <template #IdeaNo="{item}">
              <td>
                <CLink class="text-primary" @click="OnClickDetail(item)">
                  {{ item.IdeaNo }}
                </CLink>
              </td>
            </template>
          </CDataTable>
        </CCol>
      </CRow>
    </CCardBody>

    <ConceptModal
      :showModal.sync="ConceptModal"
      @ConceptModal-updated="ConceptModal = $event"
      :ideaDataSelected="IdeaList"
      @ideaListSelected-updated="IdeaList = $event"
      :TypeConcept.sync="TypeConcept"
      :viewmode="conceptViewMode"
    />

    <IdeaModal
      :showModal.sync="IdeaModal"
      @IdeaModal-updated="IdeaModal = $event"
      :viewmode="viewmode"
      :insightDataSelected="InsightSelected"
      @insightListSelected-updated="InsightSelected = $event"
      :item.sync="ideaObject"
    />
  </CCard>
</template>

<script>
import { DatePicker } from "v-calendar";
import ConceptModal from "../concept/ConceptModal";
import IdeaModal from "../idea/IdeaModal";
import axios from "axios";
import store from "../../store";
import moment from "moment"
// var URLLogLikeInsert = store.getters.URL + '/api/LogLike/insert';
// var URLLogLikeDelete = store.getters.URL + '/api/LogLike/delete';

const fields = [
  { key: "IdeaNo", label: "Idea No." },
  { key: "IndustryDesc", label: "Industry" },
  { key: "IdeaDetail", label: "Idea detail" },
  { key: "ShowIdeaCreatedDate", label: "Created Date", _classes: "text-center" },
  //{ key: "status", label: "Status" },
  {
    key: "action",
    label: "Action",
    sorter: false,
    filter: false
  }
];

export default {
  name: "InsightTank",
  components: {
    DatePicker,
    ConceptModal,
    IdeaModal,
    moment
  },
  data() {
    return {
      viewmode: "view",
      conceptViewMode: "create",
      fields,
      formCollapsed: false,
      eventDate: "",
      ConceptModal: false,
      IdeaModal: false,
      ideaObject: null,
      InsightSelected: [],
      TypeConcept: "Idea",
      IndustryList: [{ value: null, label: "--Select--" }],
      buList: [{ value: null, label: "--Select--" }],
      searchObj: {
        Creator: "",
        BU: "",
        Industry: "",
        EventDate: "",
        InsightNo: "",
        InsightDetail: "",
        IdeaNo: "",
        IdeaDetail: ""
      },
      IdeaTankData: [],
      IdeaList : [],
      IsLoading:true,
      // userprofile: JSON.parse(localStorage.getItem("userprofile"))
      userprofile: JSON.parse(localStorage.getItem("userprofile"))
    };
  },
  methods: {
    GetBu(){
      axios.get(store.getters.URL +'/api/MasterBu/getall').then((res) => {
        let options = [{ value: "", label: "-- Select --" }];
        //console.log("res", res);
        if (res !== null) {
          res.data.forEach(function (data) {
            options.push({
              value: data.BUID,
              label: data.BUDesc,
            });
          });
        }
        this.buList = options;
      });
    },
    OnExport()
    {      
      let dataForExport = []
      Array.from(this.IdeaTankData).forEach((item) => {
          let format = {
            IdeaNo: item.IdeaNo,
            Industry: item.IndustryDesc,
            IdeaDetail: item.IdeaDetail,
            CreateDate: item.ShowIdeaCreatedDate        
          };
          dataForExport.push(format)
        });
      this.OnExportToExcel("IdeaTank", dataForExport)
    },
    OnClickDetail(item) {
      this.IdeaModal = !this.IdeaModal;
      this.ideaObject = { IdeaNo : item.IdeaNo };
    },
    onClickAddConcept(item) {      
      axios
        .get(
          store.getters.URL + "/api/IdeationIdea/getbyid",
          {
            params: {
              uid: item.IdeaNo
            }
          }
        )
        .then(response => {
          if (response !== null) {
            //console.log("response",response);
            this.IdeaList = [];
            this.IdeaList.push(response.data);
          }
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.ConceptModal = true;
        });

      
    },
    // onClickLike(item) {
    //   let logLikeModel = {
    //     "DocumentNo": item.IdeaNo,
    //     "LikeUser": this.userprofile.Username,
    //     "DocumentType": 'Idea'
    //   };

    //   if (item.ILike) {
    //     axios.post(URLLogLikeDelete, logLikeModel)
    //        .then(response => {
    //          item.ILike = !item.ILike;
    //          this.$_toast_Noti('success','success', response.data);
    //        })
    //        .catch(error => {
    //          this.$_toast_Noti('error','error', error);
    //        });
    //   } 
    //   else {
    //     axios.put(URLLogLikeInsert, logLikeModel)
    //        .then(response => {
    //          item.ILike = !item.ILike;
    //          this.$_toast_Noti('success','success', response.data);
    //        })
    //        .catch(error => {
    //          this.$_toast_Noti('error','error', error);
    //        });
    //   }
    // },
    GetIndustry() {
      axios.get(store.getters.URL + "/api/MasterIndustry/getall").then(res => {
        let Options = [{ value: null, label: "-- Select --" }];
        res.data.forEach(function(data) {
          Options.push({
            value: data.IndustryID,
            label: data.IndustryDesc
          });
        });
        this.IndustryList = Options;
      });
    },
    GetIdeaTankData() {
      axios
        .get(store.getters.URL + "/api/IdeationIdea/getideatankdata",{params:{
          username:this.userprofile.Username
        }})
        .then(res => {
          //console.log(res.data.Value)
          let Obj = JSON.parse(res.data.Value);
          this.IdeaTankData = Obj;
          this.IsLoading = false
        })
        .catch(err => {
          console.error(err.error);
          this.IsLoading = false
        });
    },
    SearchIdea() {
      this.IsLoading = true
      let item = {
        Creator: this.searchObj.Creator,
        BU: this.searchObj.BU,
        Industry: this.searchObj.Industry,
        EventDate: moment(this.searchObj.EventDate,'YYYY-MM-DD').format('YYYY-MM-DD'),
        InsightNo: this.searchObj.InsightNo,
        InsightDetail: this.searchObj.InsightDetail,
        IdeaNo: this.searchObj.IdeaNo,
        IdeaDetail: this.searchObj.IdeaDetail,
        Username:this.userprofile.Username
      };
      axios
        .get(store.getters.URL + "/api/IdeationIdea/searchideatankdata", {
          params: item
        })
        .then(res => {
          this.IdeaTankData = res.data;
          this.IsLoading = false
          //console.log("result",this.IdeaTankData)
        })
        .catch(err => {
          console.error(err.error);
          this.IsLoading = false
        });
    },
    ClearValue(){
      this.searchObj.Creator =""
      this.searchObj.BU =""
      this.searchObj.Industry = ""
      this.searchObj.EventDate = ""
      this.searchObj.InsightNo = ""
      this.searchObj.InsightDetail = ""
      this.searchObj.IdeaNo = ""
      this.searchObj.IdeaDetail = ""
    }
  },
  mounted() {
    this.GetIndustry();
    this.GetBu();
    this.GetIdeaTankData();
  },
};
</script>
<style scoped>
.event-date {
  padding-bottom: 15px;
}
</style>
